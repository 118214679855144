export default {
  publicLayout: {
    moto: "…vstup do světa sběratelů",
    loginButton: "Přihlásit se",
    footer: {
      taxId: "IČ",
      menu: "Menu",
      toTop: "zpět",
      gdprLink: "@:views.termsAndConditions.heading",
      cookiesLink: "Nastavení cookies",
      legalInformationLink: "Právní informace",
    },
    menu: {
      btnTitle: "Menu",
      howItWorks: "Jak to funguje",
      magazine: "Magazín",
      about: "O nás",
      contacts: "Kontakty",
      publicItemsList: "@:appLayout.menu.publicItemsList",
    },
  },
};
