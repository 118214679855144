import { RouteLocationNormalized, createRouter, createWebHistory } from "vue-router";
import { TokenNotValidException, User, checkUserOnInitialPageLoad, getUser as getRunningAuthentication, wasSomeoneEverLoggedIn } from "@/services/user";
import { wrapPrepositions } from "@/utilities";
import { PublicRouteName, publicRoutes } from "./publicRoutes";
import { AppRouteName, appRoutes } from "./appRoutes";
import { ErrorRouteName, errorPagesRoutes } from "./errorRoutes";
import { createLoginLink, createRegistrationLink, LockingStateRouteName, lockingStateRoutes } from "./lockingStatesRoutes";
import { MetaTags } from "@/services/metaTags";
import { isPublic, updateBreadcrumbs, updatePageTitle } from "@/router/helpers";

export const RouteName = { ...PublicRouteName, ...AppRouteName, ...LockingStateRouteName, ...ErrorRouteName };

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  linkExactActiveClass: "active",
  routes: [
    {
      path: "/",
      redirect: "/cz",
    },
    {
      path: "/cz",
      children: [...publicRoutes, ...appRoutes, ...lockingStateRoutes, ...errorPagesRoutes],
    },
  ],
  scrollBehavior(to, from) {
    if (to.hash && document.getElementById(to.hash.slice(1))) {
      return { el: to.hash };
    }

    if (to.name === from.name && to.meta.preventScrollToTop === true) {
      return;
    }

    return { top: 0 };
  },
});

class LoginNeededException extends Error {}

router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next) => {
  const isRoutePublic = isPublic(to);
  updatePageTitle(to);
  updateBreadcrumbs(to);

  try {
    const user = await getUser();
    if (isRoutePublic) {
      const redirectWhenLoggedIn = to.meta.redirectWhenLoggedIn;
      if (redirectWhenLoggedIn && user) {
        return next(redirectWhenLoggedIn);
      } else {
        return next();
      }
    } else {
      if (user) {
        return next();
      } else {
        throw new LoginNeededException();
      }
    }
  } catch (e: unknown) {
    const isTokenInvalid = e instanceof TokenNotValidException;
    if (isTokenInvalid && isRoutePublic) {
      return next();
    } else if (isTokenInvalid || e instanceof LoginNeededException) {
      if (wasSomeoneEverLoggedIn()) {
        const redirect = to.fullPath;
        return next(createLoginLink(redirect));
      } else {
        return next(createRegistrationLink());
      }
    } else {
      throw e;
    }
  }
});

router.afterEach((to: RouteLocationNormalized) => {
  wrapPrepositions();

  let url = window.location.href;
  if (to.fullPath === "/") {
    url += "cz";
  }
  MetaTags.updateLinkCanonical(url);
});

const getUser = async (): Promise<User | undefined> => {
  const user = await getRunningAuthentication();
  // no currently running authentication process or no token found
  if (user === undefined) {
    const newlyAuthenticatedUser = await checkUserOnInitialPageLoad(); // check if there is any token stored and possibly start authentication
    return newlyAuthenticatedUser === null ? undefined : newlyAuthenticatedUser;
  } else {
    return user;
  }
};

export { router };
