import { API } from "@/services/api/API";
import { Events } from "@/types";
import { CatalogueWatchFilterApiData, RawWatchParameterData } from "@/types";
import { createWatchParameter } from "./watchParameterFactory";

export const Parameters = {
  url: "watch/parameters/",

  get: async function (getParamsUsedInFilterOnly = false, loadOptions = true, order = "order") {
    const params = <CatalogueWatchFilterApiData>{
      order: order,
      loadOptions: loadOptions,
    };

    if (getParamsUsedInFilterOnly) {
      params.use_in_filter = 1;
    }

    return API.post(this.url + "get", params).then((response) => {
      return (response.parameters as RawWatchParameterData[]).map((rawData) => createWatchParameter(rawData));
    });
  },

  getOptions: async function (parameterId: number, filter: number[] = []) {
    return API.getWithParams(this.url + "get-options", { filter, parameter: parameterId }).then((response) => {
      return response.options;
    });
  },

  getOptionsAll: async function (parameterId: number) {
    return API.getWithParams(this.url + "options", { parameterId }).then((response) => {
      return response.options;
    });
  },

  getOption: async function (optionId: number) {
    return API.get(this.url + "get-option/" + optionId).then((response) => {
      if (response.status === "ok") {
        return response.option;
      } else {
        window.eventBus.emit(Events.toastError, response.message);
      }
    });
  },
};
