import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import { App, inject } from "vue";
import { BuildMode } from "@/types";

/** Defined by Google. We have to manage granted/denied for each of this category. */
enum GtmCategory {
  AdStorage = "ad_storage",
  AdUserData = "ad_user_data",
  AdPersonalization = "ad_personalization",
  AnalyticsStorage = "analytics_storage",
  FunctionalityStorage = "functionality_storage",
  PersonalizationStorage = "personalization_storage",
  SecurityStorage = "security_storage",
}

/** Categories in our cookie consent window */
enum OurCookieCategory {
  Neccesary = "neccessary",
  Analytics = "analytics",
  Ads = "ads",
  Personalization = "personalization",
}

const OurCategories2GtmCategoriesMap = new Map<OurCookieCategory, GtmCategory[]>([
  [OurCookieCategory.Neccesary, [GtmCategory.FunctionalityStorage]],
  [OurCookieCategory.Analytics, [GtmCategory.AnalyticsStorage]],
  [OurCookieCategory.Ads, [GtmCategory.AdStorage, GtmCategory.AdPersonalization]],
  [OurCookieCategory.Personalization, [GtmCategory.PersonalizationStorage, GtmCategory.AdUserData, GtmCategory.SecurityStorage]],
]);

enum ConsentApprovalStatus {
  Granted = "granted",
  Denied = "denied",
}

const PluginName = "CookieConsent";

export const CookieConsentPlugin = {
  install: (app: App) => {
    const cc = CookieConsent;
    cc.run(CookieConsentConfig);
    app.provide(PluginName, cc);
  },
};

// Components' composable
export const useCookieConsent = () => {
  const cc = inject(PluginName) as typeof CookieConsent;
  const openSettings = () => cc.showPreferences();
  return { openSettings };
};

function updateGtmConsent(allowedCategories: OurCookieCategory[], rejectedCategories: OurCookieCategory[]) {
  const mode = import.meta.env.MODE;
  if (mode !== BuildMode.production) {
    return;
  }

  if (!window.gtag) {
    throw new Error("DEV: GTM not found!");
  }

  const gtmAllowedCategories = allowedCategories.map((c) => OurCategories2GtmCategoriesMap.get(c)).flatMap((i) => i);
  const gtmRejectedCategories = rejectedCategories.map((c) => OurCategories2GtmCategoriesMap.get(c)).flatMap((i) => i);

  const setup: { [key in GtmCategory]?: ConsentApprovalStatus } = {};
  const gtmCategories = Object.values(GtmCategory);
  gtmCategories.forEach((category) => {
    if (gtmAllowedCategories.includes(category)) {
      setup[category] = ConsentApprovalStatus.Granted;
    } else if (gtmRejectedCategories.includes(category)) {
      setup[category] = ConsentApprovalStatus.Denied;
    } else {
      throw new Error("DEV: Should not happen.");
    }
  });

  const PropertyName = "consent";
  const SetupType = "update";
  window.gtag(PropertyName, SetupType, setup);
}

const CookieConsentConfig: CookieConsent.CookieConsentConfig = {
  revision: 1, // Just a current cookie setup version number. Increment if new consent by users is required.
  lazyHtmlGeneration: true,
  autoClearCookies: true,
  guiOptions: {
    consentModal: {
      layout: "box wide",
      position: "bottom center",
      equalWeightButtons: true,
      flipButtons: true,
    },
  },
  categories: {
    [OurCookieCategory.Neccesary]: {
      readOnly: true,
      enabled: true,
    },
    [OurCookieCategory.Analytics]: {},
    [OurCookieCategory.Ads]: {},
    [OurCookieCategory.Personalization]: {},
  },
  language: {
    default: "cs",

    translations: {
      cs: {
        consentModal: {
          title: "Máme rádi cookies 🍪",
          description: "Abychom ti mohli nabídnout personalizovaný obsah a uložit tvé preference, používáme cookies. Dáš nám zelenou?",
          acceptAllBtn: "Přijmout vše",
          acceptNecessaryBtn: "Přijmout nezbytné",
          showPreferencesBtn: "Nastavení cookies",
          closeIconLabel: "Odmítnout vše",
        },
        preferencesModal: {
          title: "Nastavení cookies",
          acceptAllBtn: "Přijmout vše",
          acceptNecessaryBtn: "Odmítnout vše",
          savePreferencesBtn: "Uložit nastavení",
          sections: [
            {
              title: "Co jsou to cookies?",
              description: "Soubory cookies a další technologie nám pomáhají zlepšovat naše služby a pomáhají nám analyzovat výkon webu. V nastavení si můžete vybrat, které cookies můžeme používat. Svůj souhlas můžete kdykoliv odvolat.",
            },
            {
              title: "Nezbytné cookies pro správné fungování našeho webu.",
              description: "Tyto cookies jsou nezbytně nutné pro správnou funkci webu - zajišťují správné zobrazení, odesílání formulářů, vkládání zboží do košíku a podobně. Technické cookies není možné vypnout.",
              linkedCategory: OurCookieCategory.Neccesary,
            },
            {
              title: "Sledování návštěvnosti a chování na webu nám pomáhá zlepšovat naše služby.",
              description: "Statistické cookies používáme k vyhodnocování výkonu našeho webu a reklamních kampaní. Pomocí cookies určujeme počet návštěv, zdroje návštěvnosti a chování zákazníků na našem webu - např. informace o tom, co na webu hledáte a které informace jsou pro vás nejdůležitější. Na základě těchto dat můžeme provádět optimalizaci webu a vylepšovat naše služby.",
              linkedCategory: OurCookieCategory.Analytics,
            },
            {
              title: "Cookies pro cílení a reklamu",
              description: "Tyto soubory cookie se používají k zobrazování reklamy, která vás pravděpodobně bude zajímat na základě vašich zvyků při procházení. Tyto soubory cookie, jsou požadovány námi/nebo poskytovateli reklam, mohou kombinovat informace shromážděné z našich webových stránek s dalšími informacemi, které nezávisle shromáždily z jiných webových stránek, týkající se činností vašeho internetového prohlížeče v rámci jejich reklamní sítě webových stránek.",
              linkedCategory: OurCookieCategory.Ads,
            },
            {
              title: "Personalizace obsahu",
              description: "Tyto cookies nám umožní zobrazit Vám obsah dle údajů, které jsou o Vás dostupné tak, abychom se co nejlépe strefili do Vašich potřeb. Jde zejména o to, jaký obsah jste si zobrazili, či na jakém zařízení na naše stránky přicházíte.",
              linkedCategory: OurCookieCategory.Personalization,
            },
          ],
        },
      },
    },
  },
  onChange: () => {
    const allowedCategories = CookieConsent.getUserPreferences().acceptedCategories;
    const rejectedCategories = CookieConsent.getUserPreferences().rejectedCategories;
    updateGtmConsent(allowedCategories as OurCookieCategory[], rejectedCategories as OurCookieCategory[]);
  },
  onConsent: () => {
    const allowedCategories = CookieConsent.getUserPreferences().acceptedCategories;
    const rejectedCategories = CookieConsent.getUserPreferences().rejectedCategories;
    updateGtmConsent(allowedCategories as OurCookieCategory[], rejectedCategories as OurCookieCategory[]);
  },
};
