import { createProfileInitializationLink } from "@/router";
import { requireUser, hidePrices as savePricesHidden, showPrices as savePricesShown } from "@/services/user";
import { ref } from "vue";
import { useRouter } from "vue-router";

const arePricesVisible = ref<boolean>(false);

export function usePriceVisibility() {
  const user = requireUser();
  arePricesVisible.value = user.hasVisiblePrices();

  const hidePrices = () => {
    user.setShowPrices(false);
    arePricesVisible.value = false;
    savePricesHidden(); // Save settings in BE.
  };

  const showPrices = () => {
    arePricesVisible.value = true;
    user.setShowPrices(true);
    savePricesShown(); // Save settings in BE.
  };

  return {
    hidePrices,
    showPrices,
    arePricesVisible,
  };
}

export function useRedirectToNicknameDialog() {
  const router = useRouter();
  const redirectToNicknameDialog = (): void => {
    const link = createProfileInitializationLink();
    router.push(link);
  };

  return {
    redirectToNicknameDialog,
  };
}
