import { translate } from "@/services/translation";
import { Categories } from "./categories";
import { CategoryId } from "@/types";

export const ValueAllCategories = 0;
export const ValueFavouriteCategories = -1;

export function isNewApiCategory(categoryId: CategoryId): boolean {
  const newApiCategories = [CategoryId.vinyl, CategoryId.boardgame];
  return newApiCategories.includes(categoryId);
}

export const isValidCategoryId = (categoryId: number): boolean => Object.values(CategoryId).includes(categoryId);

export type CategorySelectValue = undefined | typeof ValueAllCategories | typeof ValueFavouriteCategories | number;

export type Option = {
  label: string;
  value: Exclude<CategorySelectValue, undefined>;
};

/** Options into category select. */
export async function createCategoryOptions(sortForUser = false, addOptionAll = false, addOptionFavourite = false): Promise<Option[]> {
  return Categories.getAll(sortForUser).then((categories) => {
    const options: Option[] = categories.map((category) => ({
      label: category.getName(),
      value: category.getId(),
    }));

    if (addOptionFavourite) {
      const optionFavourite = {
        label: translate("components.publicItemsFilter.categories.favourite"),
        value: ValueFavouriteCategories,
      };
      options.unshift(optionFavourite);
    }

    if (addOptionAll) {
      const optionAll = {
        label: translate("components.publicItemsFilter.categories.all"),
        value: ValueAllCategories,
      };
      options.unshift(optionAll);
    }

    return options;
  });
}
