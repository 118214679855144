<template>
  <ElTag v-bind="attrs" :class="cssClasses">
    <slot></slot>
  </ElTag>
</template>

<script lang="ts">
import { ElTag } from "element-plus";
import { computed, PropType } from "vue";

export enum TagVariant {
  gray,
  black,
  green,
}

export default {
  components: {
    ElTag,
  },
  props: {
    variant: {
      type: Number as PropType<TagVariant>,
      default: () => TagVariant.gray,
    },
  },
  setup(props, { attrs }) {
    if (props.variant === TagVariant.black) {
      throw new Error("DEV: Green tag variant not implemented yet.");
    }

    const cssClasses = computed(() => {
      return {
        "el-tag": true,
        "colectio-tag": true,
        "colectio-tag--gray": props.variant === TagVariant.gray,
        "colectio-tag--green": props.variant === TagVariant.green,
        "colectio-tag--black": props.variant === TagVariant.black,
      };
    });

    return {
      attrs,
      cssClasses,
    };
  },
};
</script>

<style lang="scss">
.el-tag.colectio-tag {
  --verticalPadding: calc(var(--spacing1x) / 2);
  --horizontalPadding: calc(var(--spacing3x) / 2);

  padding: var(--verticalPadding) var(--horizontalPadding);
  text-transform: uppercase;
  font-size: 11px;
  border-radius: var(--radius4x);
  border: 0;
  height: 22px;
  line-height: 0.5;

  &.colectio-tag--gray {
    color: var(--white);
    background-color: var(--grayTransparent500);
  }
  &.colectio-tag--green {
    color: var(--white);
    background-color: var(--green500);
  }
  &.colectio-tag--black {
    // Not implemented yet
  }
}
</style>
