import { createI18n } from "vue-i18n";
import csTexts from "@/locales/cs";

export enum Locale {
  cs = "cs",
}
const FallbackLocale = Locale.cs;

const PluralRules = {
  [Locale.cs]: function (count: number): number {
    if (count === 0) {
      return 0;
    } else if (count === 1) {
      return 1;
    } else if (count < 5) {
      return 2;
    } else {
      return 3;
    }
  },
};

export const i18n = createI18n({
  locale: Locale.cs,
  fallbackLocale: FallbackLocale,
  warnHtmlMessage: false,
  legacy: false,
  globalInjection: true,
  messages: {
    [Locale.cs]: csTexts,
  },
  pluralRules: PluralRules,
});

export const translate = i18n.global.t;
