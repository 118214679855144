<template>
  <NotificationBar v-if="!isLatest" color="green">
    <div class="version-bar">
      <div class="version-bar__icon desktop-only">
        <Icon :icon="faSparkles" />
      </div>
      <p class="version-bar__text" v-html="translate('versionBar.text')" />
      <button class="btn btn--secondary" @click="router.go(0)">
        <span class="desktop-only">{{ translate("versionBar.btnDesktop") }}</span>
        <span class="mobile-only">{{ translate("versionBar.btnMobile") }}</span>
      </button>
    </div>
  </NotificationBar>

  <transition name="fade-500">
    <div v-if="isInitialLoginCheckFinished && layout" :class="{ 'page-wrapper': true, 'with-notification': isSomeNotificationVisible }">
      <AppLayout v-if="layout.name === Layout.app" />
      <DialogLayout v-else-if="layout.name === Layout.appDialog" :settings="layout" />
      <PublicLayout v-else-if="layout.name === Layout.public" />
      <LockingState v-else-if="layout.name === Layout.lockingState" />
    </div>

    <div v-else class="loader">
      <img src="/images/logo-c-color.svg" />
    </div>
  </transition>

  <GalleryFullscreen />
  <confirmation-dialog />
  <overlay-message />
</template>

<script setup lang="ts">
import OverlayMessage from "@/components/OverlayMessage.vue";
import GalleryFullscreen from "@/components/generics/GalleryFullscreen.vue";
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import { TokenNotValidException, UserNotEstablishedException, checkUserOnInitialPageLoad, getUser, resetAuthentication } from "@/services/user";
import { computed, defineAsyncComponent, onMounted, onUnmounted, ref } from "vue";
import { isEscape } from "./services/keyDownHelpers";
import { Events, LayoutSettings, Layout } from "./types";
import { watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useRedirectToNicknameDialog, useTranslation } from "./composables";
import { useVersion } from "./composables/version";
import Icon from "@/components/basics/Icon.vue";
import { faSparkles } from "@fortawesome/pro-solid-svg-icons";
import NotificationBar from "@/components/NotificationBar.vue";
import { createLoginLink } from "./router";

const { translate } = useTranslation();

const layout = ref<LayoutSettings>();

const { version, isLatest, checkIsLatest } = useVersion();
console.log("%c" + version, "padding: 4px 8px; color: #fff; background: #32C380; border-radius: 5px");

onMounted(() => {
  window.addEventListener("keyup", onKeyUp);
});

onUnmounted(() => {
  window.removeEventListener("keyup", onKeyUp);
});

const AppLayout = defineAsyncComponent(() => import("@/components/layouts/app/Layout.vue"));
const DialogLayout = defineAsyncComponent(() => import("@/components/layouts/Dialog.vue"));
const PublicLayout = defineAsyncComponent(() => import("@/components/layouts/public/Public.vue"));
const LockingState = defineAsyncComponent(() => import("@/components/layouts/LockingState.vue"));

const onKeyUp = (e: KeyboardEvent) => {
  if (isEscape(e)) {
    window.eventBus.emit(Events.keyEscape);
  }
};

const route = useRoute();
const router = useRouter();

watch(route, async (to) => {
  const defaultLayout = to.meta.layout ?? { name: Layout.public };
  const loggedInLayout = to.meta.layoutLoggedIn;

  if (loggedInLayout) {
    try {
      const user = await getUser();
      layout.value = user ? loggedInLayout : defaultLayout;
    } catch (e: unknown) {
      if (e instanceof TokenNotValidException) {
        // It's okay, don't throw error, we can fallback to layout for not logged in users.
        layout.value = defaultLayout;
      } else {
        throw e;
      }
    }
  } else {
    layout.value = defaultLayout;
  }
});

/** This needs huge refactoring and should be in router... */
const onLogin = () => {
  const routeName = route.name;

  if (typeof routeName === "string" && !routeName.startsWith("error-")) {
    const redirect = route.query.redirect_url;
    if (typeof redirect === "string") {
      router.push(redirect);
    }
  }
};

const isInitialLoginCheckFinished = ref<boolean>(false);
const { redirectToNicknameDialog } = useRedirectToNicknameDialog();
checkUserOnInitialPageLoad()
  .then((user) => {
    if (user) {
      onLogin();
    }
  })
  .catch((e: unknown) => {
    if (e instanceof UserNotEstablishedException) {
      redirectToNicknameDialog();
    } else if (e instanceof TokenNotValidException) {
      resetAuthentication();
      const isRoutePublic = route.name === undefined || route.meta.public === true; // route.name === undefined ... first page load
      if (!isRoutePublic) {
        router.push(createLoginLink());
      }
    } else {
      throw e;
    }
  })
  .finally(() => {
    isInitialLoginCheckFinished.value = true;
  });

// Latest bundle version check
const checkInterval = 900000; // 900000 === 15 minutes
onMounted(() => setInterval(() => checkIsLatest(), checkInterval));

const isSomeNotificationVisible = computed(() => !isLatest.value);
</script>

<style lang="scss" scoped>
.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @include for-size(md) {
    min-height: 100dvh;
  }

  &.with-notification {
    padding-top: var(--notificationBarHeight);
  }
}
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    display: block;
    width: 100px;
    max-width: 90%;
    animation: loading 3s ease-in-out infinite;
    opacity: 0.9;
  }
}
.version-bar {
  display: flex;
  align-items: center;
  gap: var(--spacing2x);
  &__icon {
    width: 20px;
    height: 20px;
    color: var(--greenPrimary);
  }
  &__text {
    margin: 0 var(--spacing2x) 0 0;
  }
}

@keyframes loading {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(360deg);
  }
}
</style>
